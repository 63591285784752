/*jshint asi: false, bitwise: true, boss: false, curly: true, eqeqeq: true, eqnull: false, evil: false, forin: false, immed: true, laxbreak: false, newcap: true, noarg: true, noempty: true, nonew: false, nomen: false, onevar: true, plusplus: false, regexp: false, undef: true, sub: false, strict: false, white: false */

/*jshint browser: true, maxerr: 50, passfail: false */

/*global define: false, _gaq: false */
undefined("modules/google_analytics", ["modules/underscore", "modules/cookies", "modules/page_controller"], function defineGoogleAnalytics(_, cookies, pageController) {
  var VERSION = "0.1.11",
      queue = [],
      variantQueue = [],
      enqueue,
      enqueueVariant,
      trackEvent,
      trackTrans,
      trackPageview,
      getVisitorId;

  enqueue = function (command) {
    queue.push(command);
  };

  enqueueVariant = function (name, value) {
    variantQueue.push(name + "=" + value);
  };

  trackEvent = function (category, action, label) {
    var d = new Date();

    if (queue.length > 0) {
      _.each(queue, function (cmd) {
        _gaq.push(cmd);
      });

      queue = [];
    }

    _gaq.push(["_setCustomVar", 14, "hit_time", d.getTime(), 3]);

    _gaq.push(["_trackEvent", category, action, label]);
  };

  trackTrans = function () {
    if (queue.length > 0) {
      _.each(queue, function (cmd) {
        _gaq.push(cmd);
      });

      queue = [];
    }

    _gaq.push(["_trackTrans"]);
  };

  getVisitorId = function () {
    var d = new Date(),
        year = d.getFullYear(),
        month = d.getMonth() + 1,
        fullMonth = month < 10 ? "0" + month : month,
        day = d.getDate(),
        fullDay = day < 10 ? "0" + day : day,
        hours = d.getHours(),
        fullHours = hours < 10 ? "0" + hours : hours,
        minutes = d.getMinutes(),
        fullMinutes = minutes < 10 ? "0" + minutes : minutes,
        seconds = d.getSeconds(),
        fullSeconds = seconds < 10 ? "0" + seconds : seconds,
        time = "" + year + fullMonth + fullDay + "_" + fullHours + fullMinutes + fullSeconds,
        rand = function (max) {
      return (Math.random(99) * max).toFixed(0);
    };

    return "v_" + time + "_" + rand(99) + "_" + rand(99) + "_" + rand(99);
  };
  /*
   * Push queued data to _gaq and call _trackPageview (send the data).
   */


  trackPageview = function () {
    if (!pageController.getProperty("gaPreference").doPageTrackCall) {
      return true;
    }

    var gaPreference = pageController.getProperty("gaPreference"),
        __utma = cookies.get("__utma"),
        __utmz = cookies.get("__utmz"),
        guid = cookies.get("guid") || null,
        cp = cookies.get("cp") || null,
        userStatus = gaPreference.userStatus || "Logged Out",
        siteSection = gaPreference.siteSection || "other",
        pageType = gaPreference.pageType ? gaPreference.pageType : null,
        gaVisitorId = __utma ? __utma.split(".")[1] : null,
        gaSessionTimestamp = __utma ? __utma.split(".")[4] : null,
        pKey = cookies.get("pkey") ? cookies.get("pkey") : null,
        gender = cookies.get("gender") ? cookies.get("gender") === "m" ? "male" : "female" : "unknown",
        age = gaPreference.userAge ? gaPreference.userAge : null,
        numberOfOrders = gaPreference.userOrders ? gaPreference.userOrders : null,
        pageUrl = gaPreference.pageUrl ? gaPreference.pageUrl : document.location.pathname + document.location.search,
        pageCategory = gaPreference.pageCategory ? gaPreference.pageCategory : null,
        d = new Date(),
        saleId = gaPreference.saleId ? gaPreference.saleId : null,
        pageUrlPrefix = gaPreference.pageUrlPrefix ? gaPreference.pageUrlPrefix : null,
        saleType = gaPreference.saleType ? gaPreference.saleType : null,
        hitID = gaVisitorId + d.getTime(),
        eDialogId = gaPreference.eDialogId,
        exactTargetId = gaPreference.exactTargetId;

    if (guid) {
      enqueue(["_setCustomVar", 1, "GUID", guid, 1]);
    }

    if (cp) {
      enqueue(["_setCustomVar", 2, "Partition", cp, 1]);
    }

    if (userStatus) {
      enqueue(["_setCustomVar", 3, "Status", userStatus, 2]);
    }

    if (siteSection) {
      enqueue(["_setCustomVar", 4, "Site Section", siteSection, 3]);
    }

    if (__utma) {
      enqueue(["_setCustomVar", 5, "utma_contents", __utma, 2]);
    }

    if (gaSessionTimestamp) {
      enqueue(["_setCustomVar", 6, "Session Timestamp", gaSessionTimestamp, 2]);
    }

    if (pKey) {
      enqueue(["_setCustomVar", 7, "pkey", pKey, 2]);
    }

    if (pageType) {
      enqueue(["_setCustomVar", 8, "Page Type", pageType, 3]);
    }

    if (gender) {
      enqueue(["_setCustomVar", 9, "gender", gender, 1]);
    }

    if (age) {
      enqueue(["_setCustomVar", 10, "age", age, 1]);
    }

    if (numberOfOrders) {
      enqueue(["_setCustomVar", 11, "number of orders", numberOfOrders, 1]);
    }

    if (variantQueue.length) {
      enqueue(["_setCustomVar", 12, "Variant", variantQueue.join("|"), 1]);
      variantQueue = [];
    }

    if (saleType) {
      enqueue(["_setCustomVar", 19, "sale_type", saleType, 3]);
    }

    if (pageCategory) {
      enqueue(["_setCustomVar", 20, "page_cat", pageCategory, 3]);
    }

    if (saleId) {
      enqueue(["_setCustomVar", 21, "sale_id", saleId, 3]);
    }

    if (exactTargetId) {
      enqueue(["_setCustomVar", 22, "ExactTarget ID", exactTargetId, 1]);
    }

    if (eDialogId) {
      enqueue(["_setCustomVar", 23, "eDialog ID", eDialogId, 1]);
    }

    if (!__utma) {
      enqueue(["_setCustomVar", 24, "Visitor ID", getVisitorId(), 1]);
    }

    if (__utmz) {
      enqueue(["_setCustomVar", 25, "utmz_contents", __utmz, 2]);
    }

    enqueue(["_setCustomVar", 14, "hit_time", "" + d.getTime(), 3]);

    if (queue.length > 0) {
      _.each(queue, function (cmd) {
        _gaq.push(cmd);
      });

      queue = [];
    }

    if (pageUrlPrefix) {
      _gaq.push(["_trackPageview", pageUrlPrefix + pageUrl]);
    } else {
      _gaq.push(["_trackPageview", pageUrl]);
    }
  };

  return {
    version: VERSION,
    enqueue: enqueue,
    enqueueVariant: enqueueVariant,
    trackEvent: trackEvent,
    trackTrans: trackTrans,
    trackPageview: trackPageview
  };
});
export default {};